
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import BimTransparencyArea from "@evercam/shared/components/bim/BimTransparencyArea"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { useBimTransparencyStore } from "@/stores/bimTransparency"
import { mapStores } from "pinia"
import axios from "@evercam/shared/api/client/axios"
import { useBimCompareStore } from "@/stores/bimCompare"
import { useSnapshotStore } from "@/stores/snapshots"
import { useRecordingsStore } from "@/stores/recordings"
import { useAccountStore } from "@/stores/account"
import { AnalyticsEvent } from "@evercam/shared/types/analytics"

export default {
  name: "BimCompareViewer",
  components: {
    BimTransparencyArea,
    EvercamLoadingAnimation,
  },
  props: {
    camera: {
      type: Object,
      default: () => {},
    },
    isBottombar: {
      type: Boolean,
      default: true,
    },
    isSidebar: {
      type: Boolean,
      default: true,
    },
    isTimelinePage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cameraImage: "",
      configKonva: {
        width: 0,
        height: 0,
      },
      imageContainerStyle: {},
      konvaImage: {},
      scaleY: 0,
      scaleX: 0,
      labelMarginLeft: 0,
      labelMarginRight: 0,
      horizontalOffset: 50,
      viewerHeight: 0,
      viewerWidth: 0,
      previousBimImage: null,
    }
  },
  computed: {
    ...mapStores(
      useSnapshotStore,
      useRecordingsStore,
      useBimTransparencyStore,
      useAccountStore,
      useBimCompareStore
    ),
    evercamWatermarkDimensions() {
      const size = Math.floor(this.configKonva.width / 24)

      return `
        width: ${size}px;
        height: ${size}px;
      `
    },
    labelPosition() {
      return {
        left: `${this.labelMarginLeft}px`,
        right: `${this.labelMarginRight}px`,
      }
    },
    isLandscapeMode() {
      return window.innerWidth > window.innerHeight
    },
    showTimelineSlider() {
      const isWidget =
        this.bimCompareStore.isWidgetPreview || this.accountStore.isWidget
      const isWidgetPreviewWithTimeline =
        this.bimCompareStore.isWidgetPreview &&
        this.bimCompareStore.isBottombarPreview

      return (
        !this.bimCompareStore.finalView &&
        (!isWidget || this.isBottombar || isWidgetPreviewWithTimeline)
      )
    },
  },
  mounted() {
    if (this.recordingsStore.selectedTimestamp) {
      this.onCalendarChange(this.recordingsStore.selectedTimestamp)
    }
    this.$addEventListener("resize", () => {
      this.$setTimeout(() => {
        this.handleResize()
      }, 500)
    })
    this.updateBimTimelineLabelPosition()
  },
  beforeDestroy() {
    if (!this.accountStore.isBimCompareWidget && !this.isTimelinePage) {
      this.recordingsStore.reset()
    }
  },
  methods: {
    getBimTimelineLabel(val) {
      if (this.bimCompareStore.bimAvailableDays?.length !== 0) {
        return this.bimCompareStore.bimAvailableDays[val]
      }
    },
    updateBimTimeline() {
      if (this.bimCompareStore.bimAvailableDays?.length) {
        this.bimCompareStore.bimDatetime =
          this.bimCompareStore.fullDates[this.bimCompareStore.bimIndex]
      }
      this.renderBimImage()
      this.updateBimTimelineLabelPosition()
    },
    async renderBimData() {
      try {
        this.bimCompareStore.resetBimProperties()
        const bimData = await this.bimCompareStore.fetchBimData({
          cameraExid: this.camera?.id,
        })

        if (!bimData) {
          return
        }
        this.updateBimImageProperties(bimData)
        this.bimCompareStore.updateBimProperties(bimData)
        this.updateBimTimelineLabelPosition()
        this.renderBimImage()
      } catch (error) {
        console.error(error)
      }
    },
    async renderBimImage() {
      if (!this.bimCompareStore.finalView && this.bimCompareStore.bimMaxDate) {
        // Cancel the previous requests
        if (
          this.bimCompareStore.isBimImageLoading &&
          !this.bimCompareStore.isSnapshotLoading
        ) {
          axios.cancelRequests()
          this.bimCompareStore.latestBimImage = null
        }
        await this.bimCompareStore.fetchBimImage({
          cameraExid: this.camera?.id,
          timestamp:
            this.bimCompareStore.fullDates[this.bimCompareStore.bimIndex],
        })
      }

      if (!this.bimCompareStore.latestBimImage) {
        return
      }

      const previousBimImage = this.previousBimImage
      if (previousBimImage) {
        previousBimImage.src = ""
        previousBimImage.onload = null
      }

      const image = new window.Image()
      image.src = this.bimCompareStore.latestBimImage
      image.width = this.konvaImage.width
      image.height = this.konvaImage.height
      image.onload = () => {
        this.konvaImage.image = image
        this.bimCompareStore.isBimImageLoading = false
        this.handleResize()
      }
      this.previousBimImage = image
    },
    updateBimImageProperties(bimLayer) {
      this.viewerWidth = bimLayer?.viewerWidth
      this.viewerHeight = bimLayer?.viewerHeight
      this.scaleY = bimLayer?.scaleY
      this.scaleX = bimLayer?.scaleX
      this.x = bimLayer?.x
      this.y = bimLayer?.y
      this.konvaImage = {
        image: "",
        opacity: 1.0,
        name: "bimModel",
        x: parseFloat(bimLayer?.x),
        y: parseFloat(bimLayer?.y),
        scaleX: parseFloat(bimLayer?.scaleX),
        scaleY: parseFloat(bimLayer?.scaleY),
        rotation: parseFloat(bimLayer?.rotation),
        width: isNaN(bimLayer?.width) ? 0 : bimLayer?.width,
        height: isNaN(bimLayer?.height) ? 0 : bimLayer?.height,
      }
      this.bimCompareStore.currentLayerId = bimLayer.id
    },
    onCalendarChange(date) {
      axios.cancelRequests()
      this.bimCompareStore.snapshotCurrentDate = date
      this.getSnapshot(date)
      this.renderBimData()
      this.bimCompareStore.fetchBimMaskLayers({ cameraExid: this.camera?.id })
    },
    async getSnapshot(timestamp) {
      if (this.isLatestSnapshotDate(timestamp)) {
        this.cameraImage = this.snapshotStore.latestSnapshot.data
        this.bimCompareStore.disableLatest = true
        this.bimCompareStore.disableOldest = false

        return
      } else if (this.isOldestSnapshotDate(timestamp)) {
        this.cameraImage = this.snapshotStore.oldestSnapshot.data
        this.bimCompareStore.disableOldest = true
        this.bimCompareStore.disableLatest = false

        return
      }
      this.bimCompareStore.disableLatest = false
      this.bimCompareStore.disableOldest = false
      this.bimCompareStore.isSnapshotLoading = true
      try {
        // Add cancel token for the next requests
        const cancelToken = axios.generateCancelTokenSource()
        axios.addCancelToken(cancelToken)
        const response = await EvercamApi.recordings.nearest(
          this.camera.id,
          `${timestamp}`,
          null,
          {
            cancelToken: cancelToken.token,
          }
        )
        if (response.snapshots?.length !== 0) {
          this.cameraImage = response.snapshots[0].data
        }
      } finally {
        this.bimCompareStore.isSnapshotLoading = false
      }
    },
    isLatestSnapshotDate(date) {
      return (
        Math.abs(
          this.$moment
            .duration(
              this.$moment(date).diff(
                this.snapshotStore.latestSnapshotTimestamp
              )
            )
            .asHours()
        ) < 1
      )
    },
    isOldestSnapshotDate(date) {
      return (
        Math.abs(
          this.$moment
            .duration(
              this.$moment(date).diff(
                this.snapshotStore.oldestSnapshotTimestamp
              )
            )
            .asHours()
        ) < 1
      )
    },
    updateBimTimelineLabelPosition() {
      if (this.bimCompareStore.finalView) {
        return
      }

      this.$setTimeout(() => {
        const bimTimelineLabel =
          this.$refs.bimTimelineLabel?.getBoundingClientRect()
        const imageWidth = this.$refs.img?.$el?.clientWidth
        const TotalWidth = bimTimelineLabel?.x + bimTimelineLabel?.width
        if (bimTimelineLabel?.left < bimTimelineLabel?.width * 2) {
          this.labelMarginLeft = -0
          this.labelMarginRight = -96
        } else if (TotalWidth > imageWidth) {
          this.labelMarginLeft = -96
          this.labelMarginRight = -0
        }
      }, 500)
    },
    handleResize() {
      let cameraImg = this.$refs.img?.$refs?.image
      if (!cameraImg) {
        return
      }
      this.configKonva.width = cameraImg.clientWidth
      this.configKonva.height = cameraImg.clientHeight
      this.konvaImage.scaleX =
        (cameraImg.clientWidth * this.scaleX) / this.viewerWidth
      this.konvaImage.scaleY =
        (cameraImg.clientHeight * this.scaleY) / this.viewerHeight
      this.konvaImage.x = (cameraImg.clientWidth * this.x) / this.viewerWidth
      this.konvaImage.y = (cameraImg.clientHeight * this.y) / this.viewerHeight
      const tabs = this.accountStore.isWidget ? 0 : 35
      const bimTimelineSliderHeight =
        this.$refs.bimTimelineSlider?.clientHeight || 0
      const imageHeight =
        this.$refs.bimCompareViewer.clientHeight -
        bimTimelineSliderHeight -
        tabs
      this.imageContainerStyle = {
        height: imageHeight,
      }
    },
    trackBimCompareSlide() {
      this.$analytics.saveEvent(AnalyticsEvent.bimCompareSlide)
    },
  },
}
